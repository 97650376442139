import flat from 'flat'

function flattenObject(obj) {
  return flat.flatten(obj)
}

function unflattenObject(obj) {
  return flat.unflatten(obj)
}

export default { flattenObject, unflattenObject }
