import {
  ref, computed, nextTick, watch,
} from '@vue/composition-api'

// Notification
import dayjs from 'dayjs'
import _ from 'lodash'
import store from '@/store'
import {
  capitalizeFirstLetter, getColorVariantByPaymentStatus, strToDecimal,
} from '@/helpers/helpers'
import { transformDatetimeToHumanReadablePretty, transformDateToHumanReadablePretty } from '@/helpers/dateTimeHelper'
import flatHelper from '@/helpers/flatHelper'
import i18n from '@/libs/i18n'

export default (props, { root }) => {
  // todo: add filtering by status, type and customer
  const router = root.$router

  const refScriptListTable = ref(null)
  const dateRange = ref([
    dayjs().startOf('week').toDate(),
    dayjs().startOf('week').endOf('day').add(6, 'day')
      .toDate(),
  ])
  const dateRangeString = computed(() => `${transformDateToHumanReadablePretty(dateRange.value[0])} - ${transformDateToHumanReadablePretty(dateRange.value[1])}`)

  const queryParams = ref({
    sortBy: 'paymentDate',
    perPage: 10,
    page: 1,
    isSortDirDesc: false,
    filter: {
      from: dateRange.value[0].toISOString(),
      to: dateRange.value[1].toISOString(),
      status: [],
      type: [],
      customer: [],
    },
  })

  const routeQuery = computed(() => {
    const tmpQuery = flatHelper.unflattenObject(router.currentRoute.query)
    if (tmpQuery.currentPage) {
      tmpQuery.currentPage = parseInt(tmpQuery.currentPage, 10)
    }
    if (tmpQuery.perPage) {
      tmpQuery.perPage = parseInt(tmpQuery.perPage, 10)
    }
    if (tmpQuery.isSortDirDesc) {
      tmpQuery.isSortDirDesc = tmpQuery.isSortDirDesc === 'true'
    }

    return tmpQuery
  })

  queryParams.value = _.mergeWith(queryParams.value, routeQuery.value)
  dateRange.value = [dayjs(queryParams.value.filter.from).toDate(), dayjs(queryParams.value.filter.to).toDate()]

  const simplifyList = list => Object.keys(list)
    .sort()
    .reduce(
      (obj, key) => {
        // eslint-disable-next-line no-param-reassign
        obj[key] = list[key]
        return obj
      },
      {},
    )

  const loading = ref(false)
  const items = ref(0)
  const totalItems = ref(0)
  const perPageOptions = [10, 25, 50, 100]
  const calendarEventToCancel = ref(null)

  const list = ref([])
  const dataMeta = computed(() => ({
    from: queryParams.value.perPage * (queryParams.value.page - 1) + (totalItems.value ? 1 : 0),
    to: queryParams.value.perPage * (queryParams.value.page - 1) + items.value,
    of: totalItems.value,
  }))

  const apiSortColumnMapper = {
    customer: 'sc.lastName',
    amount: 's.amount',
    type: 's.type',
    paymentDate: 's.paymentDate',
  }

  const fetchData = () => {
    loading.value = true
    nextTick(() => {
      store.dispatch('settlement/fetchSettlements', {
        params: {
          limit: queryParams.value.perPage,
          offset: (queryParams.value.page - 1) * queryParams.value.perPage,
          orderBy: apiSortColumnMapper[queryParams.value.sortBy],
          orderDir: queryParams.value.isSortDirDesc ? 'desc' : 'asc',
          filter: {
            from: dayjs(queryParams.value.filter.from).format(),
            to: dayjs(queryParams.value.filter.to).format(),
            status: queryParams.value.filter.status,
            type: queryParams.value.filter.type,
            customerIds: queryParams.value.filter.customer.map(customer => customer.id),
          },
        },
      })
        .then(res => {
          list.value = res.data.items
          items.value = res.data.filtered
          totalItems.value = res.data.total
        })
        .finally(() => {
          loading.value = false
        })
    })
  }

  watch(() => queryParams.value, () => {
    const query = simplifyList(flatHelper.flattenObject(queryParams.value))

    if (JSON.stringify(simplifyList(flatHelper.flattenObject(routeQuery.value))) !== JSON.stringify(query)) {
      if (!query.page) {
        query.page = 1
      }

      router.push({
        query,
      })
    }

    fetchData()
  }, { deep: true })

  watch(() => dateRange.value, () => {
    queryParams.value.filter.from = dateRange.value[0].toISOString()
    queryParams.value.filter.to = dateRange.value[1].toISOString()
  })

  const getPaymentStatus = event => {
    const paymentStatus = { paid: 0, total: 0 }

    Object.values(event.calendarEventCustomers).forEach(calendarEventCustomer => {
      paymentStatus.total += parseFloat(calendarEventCustomer.settlement.amount)
      if (calendarEventCustomer.settlement.paid) {
        paymentStatus.paid += parseFloat(calendarEventCustomer.settlement.amount)
      }
    })

    return paymentStatus
  }

  const cancelMeetingHandler = calendarEvent => {
    calendarEventToCancel.value = calendarEvent
    nextTick(() => {
      root.$emit('bv::show::modal', 'cancel-event-modal-by-customer')
    })
  }

  const cancelledEventHandler = () => {
    fetchData()
  }

  const isPossibleToCancel = calendarEvent => {
    if (dayjs().isAfter(dayjs(calendarEvent.startAt))) {
      return false
    }

    return Object.values(calendarEvent.calendarEventCustomers)
      .some(calendarEventCustomer => calendarEventCustomer.customer.canCancelReservations)
  }

  fetchData()

  const settlementStatuses = computed(() => {
    const statuses = [
      { id: 'Scheduled', label: i18n.t('settlements.statuses.Scheduled') },
      { id: 'ToPay', label: i18n.t('settlements.statuses.ToPay') },
      { id: 'OverduePayment', label: i18n.t('settlements.statuses.OverduePayment') },
      { id: 'Paid', label: i18n.t('settlements.statuses.Paid') },
      { id: 'ReturnedToBalance', label: i18n.t('settlements.statuses.ReturnedToBalance') },
    ]

    return statuses.filter(s => !queryParams.value.filter.status.includes(s.id))
  })

  const settlementStatusFilter = ref(null)
  const handleSettlementStatusFilterChange = value => {
    settlementStatusFilter.value = null
    if (value !== null) {
      queryParams.value.filter.status.push(value)
    }
  }

  const settlementTypes = computed(() => {
    const types = [
      { id: 'incoming_payment', label: i18n.t('settlements.types.incoming_payment') },
      { id: 'accounting_for_classes', label: i18n.t('settlements.types.accounting_for_classes') },
      { id: 'accounting_for_late_cancellation', label: i18n.t('settlements.types.accounting_for_late_cancellation') },
      { id: 'incoming_payment_for_classes', label: i18n.t('settlements.types.incoming_payment_for_classes') },
      { id: 'correction', label: i18n.t('settlements.types.correction') },
      { id: 'return', label: i18n.t('settlements.types.return') },
    ]

    return types.filter(s => !queryParams.value.filter.type.includes(s.id))
  })

  const settlementTypeFilter = ref(null)

  const handleSettlementTypeFilterChange = value => {
    settlementTypeFilter.value = null
    if (value !== null) {
      queryParams.value.filter.type.push(value)
    }
  }

  store.dispatch('customer/fetchCustomers')
  const customers = computed(() => store.getters['customer/getCustomers']
    .map(customer => ({ id: `${customer.id}`, label: `${customer.lastName} ${customer.firstName}` }))
    .filter(customer => !queryParams.value.filter.customer.map(c => c.id).includes(customer.id)))
  const customerFilter = ref(null)

  const handleCustomerFilterChange = value => {
    customerFilter.value = null
    if (value !== null) {
      queryParams.value.filter.customer.push(value)
    }
  }

  const filtersList = computed(() => {
    let filters = []
    filters = filters.concat(queryParams.value.filter.status.map((value, index) => ({
      type: 'status',
      label: i18n.t('settlements.filters.status'),
      value: i18n.t(`settlements.statuses.${value}`),
      index,
    })))

    filters = filters.concat(queryParams.value.filter.type.map((value, index) => ({
      type: 'type',
      label: i18n.t('settlements.filters.type'),
      value: i18n.t(`settlements.types.${value}`),
      index,
    })))

    if (customers.value.length) {
      filters = filters.concat(queryParams.value.filter.customer.map((value, index) => ({
        type: 'customer',
        label: i18n.t('settlements.filters.customer'),
        value: value.label,
        index,
      })))
    }

    return filters
  })

  const removeFilter = filter => {
    queryParams.value.filter[filter.type].splice(filter.index, 1)
  }

  return {
    loading,
    totalItems,
    dataMeta,
    perPageOptions,
    dateRange,
    dateRangeString,
    queryParams,
    refScriptListTable,
    fetchData,
    list,
    calendarEventToCancel,
    getPaymentStatus,
    cancelMeetingHandler,
    cancelledEventHandler,
    isPossibleToCancel,

    // filter handles
    filtersList,
    removeFilter,

    settlementStatuses,
    settlementStatusFilter,
    handleSettlementStatusFilterChange,

    settlementTypes,
    settlementTypeFilter,
    handleSettlementTypeFilterChange,

    customers,
    customerFilter,
    handleCustomerFilterChange,

    // helpers
    capitalizeFirstLetter,
    getColorVariantByPaymentStatus,
    strToDecimal,
    transformDatetimeToHumanReadablePretty,
  }
}
