<template>
  <div>
    <b-row>
      <b-col
        cols="12"
        xl="10"
        offset-xl="1"
      >
        <!-- Table Container Card -->
        <b-card
          no-body
          class="mb-0"
        >

          <div class="m-2">

            <!-- Table Top -->
            <b-row>

              <!-- Per Page -->
              <b-col
                cols="12"
                md="8"
                class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
              >
                <label>{{ $t('shared.show') }}</label>
                <v-select
                  v-model="queryParams.perPage"
                  :options="perPageOptions"
                  :clearable="false"
                  class="per-page-selector d-inline-block mx-50"
                />
                <label>{{ $t('shared.entries') }}</label>
              </b-col>

              <!-- Search -->
              <b-col
                cols="12"
                md="4"
              >
                <div class="d-flex align-items-center justify-content-end">
                  <date-range-picker
                    v-model="dateRange"
                    :btn-text="dateRangeString"
                  />

                  <b-dropdown
                    class="ml-1"
                    variant="primary"
                    right
                  >
                    <template #button-content>
                      <b-badge
                        v-if="filtersList.length"
                        variant="light"
                        class="text-primary my-n25 mr-50 ml-n1"
                      >
                        <feather-icon
                          icon="FilterIcon"
                          size="14"
                        />
                        {{ filtersList.length }}
                      </b-badge>

                      {{ $t('shared.btn.filters') }}
                    </template>

                    <b-dropdown-text style="min-width: 300px;">
                      <label class="d-block">{{ $t('settlements.filters.status') }}
                        <v-select
                          v-model="settlementStatusFilter"
                          :options="settlementStatuses"
                          :reduce="option => option.id"
                          class=""
                          @input="handleSettlementStatusFilterChange"
                        />
                      </label>
                    </b-dropdown-text>

                    <b-dropdown-text style="min-width: 300px;">
                      <label class="d-block">{{ $t('settlements.filters.type') }}
                        <v-select
                          v-model="settlementTypeFilter"
                          :options="settlementTypes"
                          :reduce="option => option.id"
                          class=""
                          @input="handleSettlementTypeFilterChange"
                        />
                      </label>
                    </b-dropdown-text>

                    <b-dropdown-text style="min-width: 300px;">
                      <label class="d-block">{{ $t('settlements.filters.customer') }}
                        <v-select
                          v-model="customerFilter"
                          :options="customers"
                          class=""
                          @input="handleCustomerFilterChange"
                        />
                      </label>
                    </b-dropdown-text>

                  </b-dropdown>
                </div>
              </b-col>

              <b-col
                v-if="filtersList.length"
                cols="12"
                class="mt-1"
              >
                <b-button
                  v-for="filter in filtersList"
                  :key="`filter-${filter.type}-${filter.index}`"
                  v-b-tooltip.hover
                  variant="outline-primary"
                  class="m-25"
                  size="sm"
                  :title="$t('shared.btn.remove-filter')"
                  @click="removeFilter(filter)"
                >
                  {{ filter.label }}: {{ filter.value }}
                  <feather-icon icon="XIcon" />
                </b-button>
              </b-col>
            </b-row>
          </div>

          <rc-overlay :show="loading">
            <b-table
              :key="`${queryParams.currentPage}-settlements-list`"
              ref="refSettlementListTable"
              primary-key="id"
              responsive
              :items="list"
              :fields="tableColumns"
              :per-page="queryParams.perPage"
              :sort-by.sync="queryParams.sortBy"
              :sort-desc.sync="queryParams.isSortDirDesc"
              class="position-relative"
              show-empty
              :empty-text="loading ? '' : $t('shared.no-data')"
            >

              <!-- Column: Customer -->
              <template #cell(customer)="data">
                <b-link
                  variant="primary"
                  :to="{name: 'organization-customers-view', params: {customerId: data.item.customer.id}}"
                >
                  {{ data.item.customer.lastName }} {{ data.item.customer.firstName }}
                </b-link>
              </template>

              <!-- Column: Type -->
              <template #cell(type)="data">
                <b-badge
                  style="max-width: 220px"
                  class="text-wrap mr-50"
                  variant="light-primary"
                >
                  {{ $t('settlements.types.' + data.item.type) }}
                </b-badge>

                <b-badge
                  v-if="data.item.method"
                  variant="light-success"
                >
                  {{ $t('shared.payment-method.' + data.item.method) }}
                </b-badge>
              </template>

              <!-- Column: Amount -->
              <template #cell(amount)="data">
                <div class="text-right">
                  <b-badge
                    v-b-tooltip.hover
                    :variant="`light-${getColorVariantByPaymentStatus(data.item.status)}`"
                    class="text-nowrap"
                    :title="$t(`settlements.statuses.${data.item.status}`)"
                  >
                    {{ strToDecimal(data.item.amount) }} zł
                  </b-badge>
                  <div
                    v-if="data.item.servicePriceType && data.item.servicePriceType !== 'PerMeeting'"
                    class="text-nowrap font-small-2"
                  >
                    {{ $t(`shared.price-types.${data.item.servicePriceType}`).toLowerCase() }}
                  </div>
                </div>
              </template>

              <!-- Column: paymentDate -->
              <template #cell(paymentDate)="data">
                {{ data.item.paymentDate | transformDateToHumanReadablePretty }}
              </template>

              <!-- Column: calendarEvents -->
              <template #cell(calendarEvents)="data">
                <div
                  v-for="calendarEventCustomer in data.item.calendarEventCustomers"
                  :key="`calendarEventCustomer-${calendarEventCustomer.id}`"
                >
                  <b-link
                    :to="{name: 'organization-meeting-view', params: {id: calendarEventCustomer.calendarEvent.id}}"
                    :class="{'font-small-2': data.item.calendarEventCustomers.length > 1}"
                  >
                    {{ calendarEventCustomer.calendarEvent.startAt | transformDatetimeToHumanReadablePretty }}
                  </b-link>
                </div>
              </template>

              <!-- Column: Actions -->
              <template #cell(actions)="data">
                <b-dropdown variant="flat-primary">
                  <template #button-content>
                    <feather-icon icon="SettingsIcon" />
                  </template>
                  <b-dropdown-item @click="$router.push({name: 'customer-meeting-view', params: {id: data.item.id}})">
                    <feather-icon
                      icon="SearchIcon"
                      size="14"
                      class="mr-25"
                    />
                    {{ $t('meeting.list.view') }}
                  </b-dropdown-item>
                  <b-dropdown-item
                    v-if="isPossibleToCancel(data.item)"
                    @click="cancelMeetingHandler(data.item)"
                  >
                    <feather-icon
                      icon="TrashIcon"
                      size="14"
                      class="mr-25"
                    />
                    {{ $t('meeting.list.cancel') }}
                  </b-dropdown-item>
                  <!--                  <b-dropdown-divider />-->
                </b-dropdown>
              </template>

            </b-table>
          </rc-overlay>

          <div class="mx-2 mb-2">
            <b-row>

              <b-col
                cols="12"
                sm="6"
                class="d-flex align-items-center justify-content-center justify-content-sm-start"
              >
                <span class="text-muted">{{ $t('shared.pagination-summary', dataMeta) }}</span>
              </b-col>
              <!-- Pagination -->
              <b-col
                cols="12"
                sm="6"
                class="d-flex align-items-center justify-content-center justify-content-sm-end"
              >
                <b-pagination
                  v-if="totalItems > queryParams.perPage"
                  v-model="queryParams.page"
                  :total-rows="totalItems"
                  :per-page="queryParams.perPage"
                  first-number
                  last-number
                  class="mb-0 mt-1 mt-sm-0"
                  prev-class="prev-item"
                  next-class="next-item"
                >
                  <template #prev-text>
                    <feather-icon
                      icon="ChevronLeftIcon"
                      size="18"
                    />
                  </template>
                  <template #next-text>
                    <feather-icon
                      icon="ChevronRightIcon"
                      size="18"
                    />
                  </template>
                </b-pagination>

              </b-col>

            </b-row>
          </div>
        </b-card>

      </b-col>
    </b-row>
  </div>
</template>

<script>
import { BDropdown, BDropdownItem, VBTooltip } from 'bootstrap-vue'
import vSelect from 'vue-select'
import listSetup from '@/views/organization/settlements/listSetup'
import RcOverlay from '@/layouts/components/rc/RcOverlay'
import DateRangePicker from '@/views/_global/date-range-picker/DateRangePicker.vue'

export default {
  components: {
    BDropdown,
    BDropdownItem,
    DateRangePicker,
    RcOverlay,
    vSelect,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  computed: {
    me() {
      return this.$store.getters['auth/getUser']
    },
    tableColumns() {
      return [
        {
          key: 'customer',
          label: this.$t('customer.settlements.list.customer'),
          sortable: true,
        },
        {
          key: 'amount',
          label: this.$t('customer.settlements.list.amount'),
          sortable: true,
        },
        {
          key: 'type',
          label: this.$t('customer.settlements.list.type'),
          sortable: false,
        },
        {
          key: 'paymentDate',
          label: this.$t('customer.settlements.list.payment-date'),
          sortable: true,
        },
        {
          key: 'calendarEvents',
          label: this.$t('customer.settlements.list.calendar-events'),
          sortable: false,
        },
        // { key: 'actions', label: this.$t('shared.actions'), sortable: false },
      ]
    },
  },
  setup: listSetup,
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>
